import { Injectable } from '@angular/core';
import { DefaultService } from './default-service';
import { SubscriptionService } from './subscription';
import { ApiService } from './api-service';
import { SpinnerService } from './spinner.service';
import { AlertService } from './alert.service';
import {BehaviorSubject, Observable} from "rxjs";
import {VariableService} from "./variable.service";

@Injectable({ providedIn: 'root' })
export class EntityService extends DefaultService {
    urlRedirection = '/security/entities/item/';
    list: any[] = [];
    queriesSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);
    queries: any[] = [];
    isQueriesFinished: boolean = false;



    // item: {key: value}
    set _queries(item: any) {
      this.queries.push(item);
      // this.isQueriesFinished = _.every(this.queries, function(o) { return o.value? true: false; });
      this.queriesSubject.next(this.queries);
    }

    get _queries(){
      return this.queries;
    }
    constructor(
        protected apiService: ApiService,
        protected subscriptionService: SubscriptionService,
        public spinnerService: SpinnerService,
        public alertService: AlertService,
        private variableService: VariableService
    ) {
        super(apiService, subscriptionService, spinnerService, alertService);
        this._getListUrl = 'getEntities';
        this._postUrl = 'postEntity';
        this._getUrl = 'getEntity';
        this._deleteUrl = '';
        this._putUrl = 'putEntity';
    }


  createRequest(keyQueries): Observable<any> {
    // this.spinnerService._isLoading = true;
    return new Observable<any>(observer => {
      let nbResult = 0;
      keyQueries.map( item => {
        if (!localStorage.getItem(item.key)){
          this.list[item.key] = [];
          const criteria = [
            {
              key: 'category.name',
              value: item.key,
              filter: '' // gt|gte|lt|lte
            },
            {
              key: 'order',
              value: 'ASC',
              filter: 'id' // after|before|strictly_after|strictly_before
            },
            {
              key: 'itemsPerPage',
              value: 500
            }
          ];
          this.variableService.getAPIListDefaultBy(criteria, true, true).subscribe( (resources: any[]) => {
            this.list[item.key] = resources["hydra:member"];
            localStorage.setItem(item.key, JSON.stringify(resources["hydra:member"]));
            item.value = true;
            this._queries = item;
            observer.next(this.list);
            nbResult++;
            if (keyQueries.length == nbResult) {
              observer.complete();
            }
          });
        } else {
          this.list[item.key] = JSON.parse(localStorage.getItem(item.key));
          observer.next(this.list);
        }
      });
    });
  }
}
