import { Component, OnInit } from '@angular/core';
import { RouteEventsService } from './core/services/RouteEventsService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'RCHIVE© //SPORT';

  constructor(
    private routeEventsService: RouteEventsService
  ) {
    this.routeEventsService.loadCurrentUrl();
  }

  ngOnInit(): void {}

}
