import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({ providedIn: 'root' })
export class FormGeneratePageService {
    private item: any = null;
    private itemSubject:BehaviorSubject<any> = new BehaviorSubject([]);

    private eltRef: any = null;
    private eltRefSubject:BehaviorSubject<any> = new BehaviorSubject([]);

    set _item(data: any) {
      this.item = data;
      console.log(this.item);
      this.itemSubject.next(this.item);
    }
    get _item(){
      return this.item;
    }

    get _itemSubject(){
      return this.itemSubject;
    }

    set _eltRef(data: any) {
      this.eltRef = data;
      this.eltRefSubject.next(this.eltRef);
    }
    get _eltRef(){
      return this.eltRef;
    }

    constructor() {}
}
