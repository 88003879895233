import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { UserService } from 'src/app/core/services/user.service';
import { EntityService } from 'src/app/core/services/entity.service';
import { AddressesUserService } from 'src/app/core/services/addresses-user.service';
import { ContactInformationsUserService } from 'src/app/core/services/contactInformationsUser.service';
import { LogService } from 'src/app/core/services/log.service';
import { ResultSearchService } from 'src/app/core/services/result-search.service';
import { RoleService } from 'src/app/core/services/role.service';
import { UnityService } from 'src/app/core/services/unity.service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  formClass = "search-form";
  form: FormGroup;
  template: any[]= [];

  currentPos: number = 0;
  dContext: string[] = [];
  wContext: string[] = ['in'];
  dAction: string[] = [];
  wAction: string[] = ['search'];
  dBy: any[] = [];
  wBy: string[] = ['lastname'];

  wordsToRemove: string[] = ['by '];

  serviceOnRequest: any;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private formBuilder: FormBuilder,
    private addressesService: AddressesUserService,
    private logService: LogService,
    private contactInformationsService: ContactInformationsUserService,
    private userService: UserService,
    private entityService: EntityService,
    private roleService: RoleService,
    private unityService: UnityService,
    private resultSearchService: ResultSearchService
  ) {
    this.template = [
      {
        type:"text",
        label:"Recherche",
        name: "request",
        prepend: "fab fa-searchengin",
        append: "Rechercher",
        function: 'onSubmit()',
        hasLabel: false,
        isInList: false,
        disable: false,
        validators: []
      }

    ];

    const objectsForm: any = {};
    this.template.map( field => {
      if (field.type !== 'actions') {
        objectsForm[field.name] = [ {value: null, disabled: field?.disable}, field.validators];
      }
    });
    this.form = this.formBuilder.group(objectsForm);
  }

  ngOnInit(): void {
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e) {
    e.preventDefault();
    localStorage.removeItem('token');

    if (!localStorage.getItem('token')) {
      this.router.navigate(['/auth/login']);
    }
  }

  onSearch(e) {
    // exemple "dans utilisateur rechercher par nom mainard%"
    // exemple "in user search by lastname mainard%"
    //suppression de mots parasites
    var str = e.form.value.request;
    if (str !== null && str.length > 3) {

      this.wordsToRemove.map( word => {
        str = str.replace(word,'');
      });
      let aOfWord: string[] = _.split(str, ' ');
      this.dAction = [''];
      this.dBy = [''];
      // recherche context "dans/in"
      this.dContext = [];
      this.searchIn('Context', aOfWord);
      // recherche action "search by/rechercher par"
      this.searchIn('Action', aOfWord);
      // recherche by "nom/name"
      this.searchIn('By', aOfWord, true);
      switch(this.dContext[0]){
        case 'user':
        case 'utilisateur':
          this.serviceOnRequest = this.userService;
          break;
        case 'entity':
        case 'tiers':
          this.serviceOnRequest = this.entityService;
          break;
        case 'address':
        case 'adresse':
          this.serviceOnRequest = this.addressesService;
          break;
        case 'contact':
          this.serviceOnRequest = this.contactInformationsService;
          break;
        case 'groupe':
        case 'group':
        case 'unity':
          this.serviceOnRequest = this.unityService;
          break;
        case 'role':
          this.serviceOnRequest = this.roleService;
          break;
        case 'log':
          this.serviceOnRequest = this.logService;
          break;

      }

      const criteria = [
        // {
        //     key: 'user.id',
        //     value: this.user.id,
        //     filter: '' // gt|gte|lt|lte
        // },
        {
            key: 'order',
            value: 'DESC',
            filter: 'id' // after|before|strictly_after|strictly_before
        },
        {
            key: 'itemsPerPage',
            value: 30
        }
      ];
      this.dBy.map( item => {
        let obj: any = {};

        Object.keys(item).forEach((_key: any) => {
          obj.key = _key;
          if(_.startsWith(item[_key], '%') && _.endsWith(item[_key], '%')) {
            obj.value = item[_key].substring(1, -1);
          }
          if(_.startsWith(item[_key], '%')) {
            obj.value = item[_key].substring(1);
          }
          if(_.endsWith(item[_key], '%')) {
            obj.value = item[_key].substring(0, item[_key].length -1);
          }

        });

        obj.filter = '';
        criteria.push(obj);
      });
      this.serviceOnRequest.getAPIListDefaultBy(criteria).subscribe( (resources: any[]) => {
        this.resultSearchService._service = this.serviceOnRequest;
        this.resultSearchService._results = resources['hydra:member'];
        this.resultSearchService._totalItems = resources['hydra:totalItems'];
        this.router.navigate(['/dashboard']);

      });
    }

  }

  searchIn(to: string = '', aOfWord: string[] = [], isCriteria = false){
    this[`w${to}`].map( wordSearch => {
      let i = 0;
      aOfWord.map(wordToSearch => {
        if (_.toUpper(wordToSearch) === _.toUpper(wordSearch)) {
          let obj: any = aOfWord[i+1];
          if (isCriteria) {
            obj = {};
            obj[`${wordSearch}`] = aOfWord[i+1];
          }
          this[`d${to}`].push(obj);
        }
        i++;
      });
    });
  }


}
