import { Injectable } from '@angular/core';
import { DefaultService } from './default-service';
import { SubscriptionService } from './subscription';
import { ApiService } from './api-service';
import { SpinnerService } from './spinner.service';
import { AlertService } from './alert.service';
import {BehaviorSubject} from "rxjs";
import {Router} from "@angular/router";

@Injectable({ providedIn: 'root' })
export class UserService extends DefaultService {
    urlRedirection = '/security/users/item/';

    private user: any;
    private _userSubject:BehaviorSubject<any> = new BehaviorSubject([]);


  constructor(
        protected apiService: ApiService,
        protected subscriptionService: SubscriptionService,
        public spinnerService: SpinnerService,
        public alertService: AlertService,
        private router: Router
    ) {
        super(apiService, subscriptionService, spinnerService, alertService);
        this._getListUrl = 'getUsers';
        this._postUrl = 'postUser';
        this._getUrl = 'getUser';
        this._deleteUrl = '';
        this._putUrl = 'putUser';
    }

    set _user(data: any) {
      this.user = data;
      this._userSubject.next(this.user);
    }
    get _user(){
      if(!this.apiService.isTokenExpired()) {
        return this.user;
      } else {
        this.router.navigate(['/auth/login']);
      }
    }
}
