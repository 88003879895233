import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DefaultListHtmlComponent } from './default-components/default-list-html/default-list-html.component';
import { DefaultListComponent } from './default-components/default-list.component';
import { DefaultListWithFormComponent } from './default-components/default-list-with-form.component';
import { DefaultItemComponent } from './default-components/default-item.component';
import { DefaultFormComponent } from './default-components/default-form.component';
import { FormGeneratorComponent } from './form-generator/form-generator.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { DefaultListToAnotherComponent } from './default-components/default-list-to-another/item.component';
import {DragDropModule} from "@angular/cdk/drag-drop";


@NgModule({
  declarations: [
    FormGeneratorComponent,
    DefaultListComponent,
    DefaultListHtmlComponent,
    DefaultListWithFormComponent,
    DefaultItemComponent,
    DefaultFormComponent,
    DefaultListToAnotherComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule, // Ng-select
    NgbPaginationModule,
    RouterModule
  ],
  exports: [
    DragDropModule,
    FormGeneratorComponent,
    DefaultListComponent,
    DefaultListHtmlComponent,
    DefaultListWithFormComponent,
    DefaultItemComponent,
    DefaultFormComponent,
    DefaultListToAnotherComponent

  ],
  providers: [
  ]
})
export class ComponentModule { }
