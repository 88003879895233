import { Component, OnInit } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { AlertService } from 'src/app/core/services/alert.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {
  isLoading: boolean;

  constructor(
    private router: Router,
    public spinnerService: SpinnerService,
    public alertService: AlertService
  ) {

    // Spinner for lazyload modules
    router.events.forEach((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.spinnerService._isLoading = true;
        this.isLoading = this.spinnerService._isLoading;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.spinnerService._isLoading = false;
        this.isLoading = this.spinnerService._isLoading;
      }
    });


  }

  ngOnInit(): void {
  }

  closeAlert() {
    this.alertService._isLoading = false;
  }
}
