import { Injectable } from '@angular/core';
import { DefaultService } from './default-service';
import { SubscriptionService } from './subscription';
import { ApiService } from './api-service';
import { SpinnerService } from './spinner.service';
import { AlertService } from './alert.service';

@Injectable({ providedIn: 'root' })
export class LogService extends DefaultService {

    constructor(
        protected apiService: ApiService,
        protected subscriptionService: SubscriptionService,
        public spinnerService: SpinnerService,
        public alertService: AlertService
    ) {
        super(apiService, subscriptionService, spinnerService, alertService);
        this._getListUrl = 'getLogs';
        this._postUrl = '';
        this._getUrl = 'getLog';
        this._deleteUrl = '';
        this._putUrl = '';
    }
}