import { Injectable } from '@angular/core';
import { DefaultService } from './default-service';
import { SubscriptionService } from './subscription';
import { ApiService } from './api-service';
import { SpinnerService } from './spinner.service';
import { AlertService } from './alert.service';

@Injectable({ providedIn: 'root' })
export class UnityService extends DefaultService {
    urlRedirection = '/security/unities/item/';
    constructor(
        protected apiService: ApiService,
        protected subscriptionService: SubscriptionService,
        public spinnerService: SpinnerService,
        public alertService: AlertService
    ) {
        super(apiService, subscriptionService, spinnerService, alertService);
        this._getListUrl = 'getUnities';
        this._postUrl = 'postUnity';
        this._getUrl = 'getUnity';
        this._deleteUrl = '';
        this._putUrl = 'putUnity';
    }
}