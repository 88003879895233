import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { apiUrls } from 'src/environments/environment';
import { AlertService } from './alert.service';
import { ApiService } from './api-service';
import { SpinnerService } from './spinner.service';
import { SubscriptionService } from './subscription';


@Injectable()
export class DefaultService implements OnDestroy{
    private item: any = {};
    private _itemSubject:BehaviorSubject<any> = new BehaviorSubject([]);
    private _itemsSubject:BehaviorSubject<any[]> = new BehaviorSubject([]);
    private items: any[] = [];
    private getUrl = '';
    private getListUrl = '';
    private postUrl = '';
    private putUrl = '';
    private deleteUrl = '';


    get _object(){
        return this.item;
    }
    set _item(data: any) {
        this.item = data;
        this._itemSubject.next(this.item);
    }

    set _items(data: any[]) {
        this.items = data;
        this._itemsSubject.next(this.items);
    }
    get _items(){
        return this.items;
    }


    set _getUrl(data: string) {
        this.getUrl = data;
    }

    get _getUrl() {
        return this.getUrl;
    }

    set _getListUrl(data: string) {
        this.getListUrl = data;
    }

    get _getListUrl() {
        return this.getListUrl;
    }

    set _postUrl(data: string) {
        this.postUrl = data;
    }

    get _postUrl() {
        return this.postUrl;
    }

    set _putUrl(data: string) {
        this.putUrl = data;
    }

    get _putUrl() {
        return this.putUrl;
    }

    set _deleteUrl(data: string) {
        this.deleteUrl = data;
    }

    get _deleteUrl() {
        return this.deleteUrl;
    }

    APIListDefaultSubscription: Subscription = null;
    APIDefaultSubscription: Subscription = null;
    listOfSubscription: Subscription[] = [
        this.APIListDefaultSubscription,
        this.APIDefaultSubscription,
    ];

    constructor(
        protected apiService: ApiService,
        protected subscriptionService: SubscriptionService,
        public spinnerService: SpinnerService,
        public alertService: AlertService
    ) {
    }

    ngOnDestroy(): void {
        this.subscriptionService.destroyListSubscription(this.listOfSubscription);
    }

    /**
     * get a list of campai
     * @param keyValue
     */
    getAPIListDefaultBy(keyValue: any[], bRepeated: boolean = false, isConnected: boolean = true): Observable<any> {
        // this.spinnerService._isLoading = true;
      if(!this.apiService.isTokenExpired()){
        return new Observable<any>(observer => {
          this.APIListDefaultSubscription = this.apiService.getObservable(this.apiService.createUrl(apiUrls[this.getListUrl], keyValue), isConnected)
            .subscribe(
              (list: any[]) => {
                this._items = list;
                // this.spinnerService._isLoading = false;
                observer.next(list);
                observer.complete();
                if (!bRepeated) {
                  this.subscriptionService.destroySubscription(this.APIListDefaultSubscription);
                }
              }
            );
        });
      }
    }

    /**
     *
     * @param id get a item by id
     */
    getAPIDefaultById(id, bRepeated: boolean = false, isConnected: boolean = true): Observable<any> {
        // this.spinnerService._isLoading = true;
        return new Observable<any>(observer => {
            this.APIDefaultSubscription = this.apiService.getObservable(apiUrls[this.getUrl] + id, isConnected)
                .subscribe(
                    (obj: any) => {
                        this._item = obj;
                        // this.spinnerService._isLoading = false;
                        observer.next(obj);
                        observer.complete();
                        if (!bRepeated) {
                            this.subscriptionService.destroySubscription(this.APIDefaultSubscription);
                        }
                    }
                );
        });
    }

    /**
     *
     * @param @id get a item by @id
     */
    getAPIDefaultByArobaseId(arobaseId, bRepeated: boolean = false, isConnected: boolean = false): Observable<any> {
        // this.spinnerService._isLoading = true;
        return new Observable<any>(observer => {
            this.APIDefaultSubscription = this.apiService.getObservable(arobaseId, isConnected)
                .subscribe(
                    (obj: any) => {
                        this._item = obj;
                        // this.spinnerService._isLoading = false;
                        observer.next(obj);
                        observer.complete();
                        if (!bRepeated) {
                            this.subscriptionService.destroySubscription(this.APIDefaultSubscription);
                        }
                    }
                );
        });
    }

    /**
     * post a item
     */
    postAPIDefault(json: any, bRepeated: boolean = false, isConnected: boolean = true): Observable<any> {
        this.spinnerService._isLoading = true;
        return new Observable<any>(observer => {
          this.APIDefaultSubscription = this.apiService.postObservable(apiUrls[this.postUrl], json, isConnected)
              .subscribe(
                  (obj: any) => {
                      this._item = obj;
                      this.success();
                      observer.next(obj);
                      observer.complete();
                      if (!bRepeated) {
                          this.subscriptionService.destroySubscription(this.APIDefaultSubscription);
                      }
                  }
              );
        });
    }

  /**
   * post a item
   */
  postAPIByRoute(route: '', json: any, bRepeated: boolean = false, isConnected: boolean = true): Observable<any> {
    this.spinnerService._isLoading = true;
    return new Observable<any>(observer => {
      this.APIDefaultSubscription = this.apiService.postObservable(apiUrls[route], json, isConnected)
        .subscribe(
          (obj: any) => {
            this._item = obj;
            this.success();
            observer.next(obj);
            observer.complete();
            if (!bRepeated) {
              this.subscriptionService.destroySubscription(this.APIDefaultSubscription);
            }
          }
        );
    });
  }

    /**
     * put a item
     * @param keyValue
     */
    putAPIDefaultById(id, json: any, bRepeated: boolean = false, isConnected: boolean = true): Observable<any> {
        this.spinnerService._isLoading = true;
        return new Observable<any>(observer => {
            this.APIDefaultSubscription = this.apiService.putObservable(apiUrls[this.putUrl] + id, json, isConnected)
                .subscribe(
                    (obj: any) => {
                        this._item = obj;
                        this.success();
                        observer.next(obj);
                        observer.complete();
                        if (!bRepeated) {
                            this.subscriptionService.destroySubscription(this.APIDefaultSubscription);
                        }
                    },
                    (error) => {
                      observer.next(error);
                      observer.complete();
                       //Handle the error here
                       //If not handled, then throw it
                    //    throw error;
                    }
                );
        });
    }
    /**
     * put a item
     * @param keyValue
     */
    putAPIDefaultByUri(uri ='', json: any, bRepeated: boolean = false, isConnected: boolean = true): Observable<any> {
        this.spinnerService._isLoading = true;
        return new Observable<any>(observer => {
            this.APIDefaultSubscription = this.apiService.putObservable(this.apiService.removePrefixApiInUrl(uri), json, isConnected)
                .subscribe(
                    (obj: any) => {
                        this._item = obj;
                        this.success();
                        observer.next(obj);
                        observer.complete();
                        if (!bRepeated) {
                            this.subscriptionService.destroySubscription(this.APIDefaultSubscription);
                        }
                    }
                );
        });
    }


    success() {
        this.spinnerService._isLoading = false;
        // if(this.item['@type'] === 'hydra:Error') {
        //     this.spinnerService._isLoading = false;
        //     this.alertService._isLoading = true;
        //     this.alertService._type = 'icon-danger';
        //     this.alertService._icon = 'fas fa-skull-crossbones';
        //     this.alertService._text = 'Demande d\'enregistrement en erreur';
        // } else {
        //     this.spinnerService._isLoading = false;
        //     this.alertService._isLoading = true;
        //     this.alertService._type = 'icon-primary';
        //     this.alertService._icon = 'fas fa-paper-plane';
        //     this.alertService._text = 'Demande d\'enregistrement réussie';
        // }
    }

    // /**
    //  * remove a object
    //  * @param keyValue
    //  */
    // removeAPIDefaultById(id, bRepeated: boolean = false, isConnected: boolean = true): Observable<any> {
    //     return new Observable<any>(observer => {
    //         this.APIDefaultSubscription = this.apiService.deleteObservable(apiUrls[this.deleteUrl] + id, isConnected)
    //             .subscribe(
    //                 (obj: any) => {
    //                     observer.next(obj);
    //                     observer.complete();
    //                 }
    //             );
    //     });
    // }

    /**
     * remove a object
     * @param keyValue
     */
    removeAPIDefaultByUri(uri ='', bRepeated: boolean = false, isConnected: boolean = true): Observable<any> {
        return new Observable<any>(observer => {
            this.APIDefaultSubscription = this.apiService.deleteObservable(uri, isConnected)
                .subscribe(
                    (obj: any) => {
                        observer.next(obj);
                        observer.complete();
                    }
                );
        });
    }
}
