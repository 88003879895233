import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ResultSearchService {
    resultsSubject:BehaviorSubject<any>;
    results: any[] = [];

    set _results(data: any[]) {
        this.results = data;
        this.resultsSubject.next({data: this.results, service:this.service, totalItems: this.totalItems});
    }

    service: any = '';

    set _service(data: any) {
        this.service = data;
        this.resultsSubject.next({data: this.results, service:this.service, totalItems: this.totalItems});
    }

    totalItems: any = '';

    set _totalItems(data: any) {
        this.totalItems = data;
        this.resultsSubject.next({data: this.results, service:this.service, totalItems: this.totalItems});
    }

    constructor() {
        this.resultsSubject = new BehaviorSubject({data: this.results, service:this.service, totalItems: this.totalItems});
    }
}