import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'default-list-html',
  templateUrl: './default-list-html.component.html'
})

export class DefaultListHtmlComponent {

  @Input('title') title: string;
  @Input('items') items: any[];
  @Input('columns') columns: any[];
  @Input('totalItems') totalItems: number;
  @Input('currentPage') currentPage: number = 1;
  @Input('previousPage') previousPage: number;
  @Input('itemsPerPage') itemsPerPage: number = 30;
  @Input('maxSize') maxSize: number = 5;
  @Input('defaultService') defaultService: number;
  @Input('dropDownButtonVisible') dropDownButtonVisible: boolean = true;

  @Output() isClickEvent = new EventEmitter();
  @Output() isLoadPaveEvent = new EventEmitter();

  constructor() {
  }

  clickEvent(item, isView) {
    this.isClickEvent.emit({
      item: item,
      isView: isView
    });
  }


  loadPage($event) {
    this.isLoadPaveEvent.emit($event);
  }

  getLabelObject(item: any, col: any) {
    let obj: any = col?._items.find(obj => obj['@id'] === item[col?.name]);
    let label = '';
    if (obj){
      label = obj[col?.prop];
    }
    return label;
  }
}
