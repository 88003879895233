// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://owl-back.nagelibre.fr/',
  urlFront: '*'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

export const apiUrls = {
  authentication: 'authentication_token',
  register: 'api/users/register/adminsys',
  retreive: 'retreive',
  getLogs: 'api/log_requests',
  getLog: 'api/log_requests/',

  getUsers: 'api/users',
  postUser: 'api/users',
  postUserByUserWithRights: 'api/users/create',
  putUser: 'api/users/',
  getUser: 'api/users/',
  getUserByToken: 'api/users/token',


  getAddressesUser: 'api/address_users',
  postAddressUser: 'api/address_users',
  putAddressUser: 'api/address_users/',
  getAddressUser: 'api/address_users',


  getAddressesEntities: 'api/address_entities',
  postAddressEntities: 'api/address_entities',
  putAddressEntities: 'api/address_entities/',
  getAddressEntities: 'api/address_entities/',


  getContactInformationsUser: 'api/contact_informations',
  postContactInformationUser: 'api/contact_informations',
  putContactInformationUser: 'api/contact_informations/',
  getContactInformationUser: 'api/contact_informations/',


  getEntities: 'api/entities',
  postEntity: 'api/entities',
  putEntity: 'api/entities/',
  getEntity: 'api/entities/',
  uriEntity: '/api/entities/',


  getUnities: 'api/unities',
  postUnity: 'api/unities',
  putUnity: 'api/unities/',
  getUnity: 'api/unities/',


  getRoles: 'api/roles',
  postRole: 'api/roles',
  putRole: 'api/roles/',
  getRole: 'api/roles/',
  removeRoleOfUnity: 'api/roles/unity?uniqId={uniqId}&idrole={idrole}&idunity={idunity}',


  getVariables: 'api/variables',
  postVariable: 'api/variables',
  putVariable: 'api/variables/',
  getVariable: 'api/variables/',
};
