import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { SubscriptionService } from './subscription';
import { apiUrls, environment } from 'src/environments/environment';
import { AlertService } from './alert.service';
import { JwtHelperService } from '@auth0/angular-jwt';

const BASE_URL = environment.apiUrl;

@Injectable()
export class ApiService implements OnDestroy {

    getObservableSubscription: Subscription = null;
    getExternalObservableSubscription: Subscription = null;
    getFileObservableSubscription: Subscription = null;
    postObservableSubscription: Subscription = null;
    postSubscription: Subscription = null;
    putSubscription: Subscription = null;
    putObservableSubscription: Subscription = null;
    deleteObservableSubscription: Subscription = null;
    postFileSubscription: Subscription = null;
    postFileFormSubscription: Subscription = null;

    listOfSubscription: Subscription[] = [
        this.getObservableSubscription,
        this.getExternalObservableSubscription,
        this.getFileObservableSubscription,
        this.postObservableSubscription,
        this.postSubscription,
        this.putSubscription,
        this.putObservableSubscription,
        this.deleteObservableSubscription,
        this.postFileSubscription,
        this.postFileFormSubscription,
    ];


    constructor(
        protected httpClient: HttpClient,
        protected router: Router,
        private subscriptionService: SubscriptionService,
        public alertService: AlertService
    ) {
    }

    ngOnDestroy(): void {
        this.subscriptionService.destroyListSubscription(this.listOfSubscription);
    }

    public isTokenExpired() {
      let rawToken = localStorage.getItem('token');
      const helper = new JwtHelperService();

      let decodedToken = helper.decodeToken(rawToken);
      let currentTimeInMilliseconds=Date.now();
      // Other functions
      let expirationDate = helper.getTokenExpirationDate(rawToken);

      return helper.isTokenExpired(rawToken);
    }

  /**
   * @param url
   * @param formData
   * @param isConnected
   * @param httpParams
   */
  public postObservable(url: string, formData: FormData, isConnected?: boolean, httpParams?: any[]): Observable<any> {
    return new Observable<any>(observer => {
        this.postObservableSubscription = this.httpClient
          .post<any>(
            BASE_URL + url,
            formData,
            {
                headers: this.httpAutorizationOauth(isConnected),
                params: this.httpParams(httpParams)
            }
          ).subscribe(
            (resource: any) => {
                if (url !== apiUrls.authentication) {
                    this.alertService._isLoading = true;
                    this.alertService._type = 'icon-primary';
                    this.alertService._icon = 'fas fa-paper-plane';
                    this.alertService._text = 'Demande d\'enregistrement réussie';
                }
                observer.next(resource);
            },
            (error: any) => {
                if (url !== apiUrls.authentication) {
                    this.alertService._isLoading = true;
                    this.alertService._type = 'icon-danger';
                    this.alertService._icon = 'fas fa-skull-crossbones';
                    this.alertService._text = 'Demande d\'enregistrement en erreur';
                }
                observer.next(error);
            }
          );
    });
  }


  /**
   * @param url
   * @param formData
   * @param isConnected
   * @param httpParams
   */
  public putObservable(url: string, formData: FormData, isConnected?: boolean, httpParams?: any[]): Observable<any> {
    return new Observable<any>(observer => {
        this.putObservableSubscription = this.httpClient
            .put<any>(
                BASE_URL + url,
                formData,
                {
                  headers: this.httpAutorizationOauth(isConnected),
                  params: this.httpParams(httpParams)
                }
            ).subscribe(
                (resource: any) => {
                    this.alertService._isLoading = true;
                    this.alertService._type = 'icon-primary';
                    this.alertService._icon = 'fas fa-paper-plane';
                    this.alertService._text = 'Demande d\'enregistrement réussie';
                    observer.next(resource);
                },
                (error: any) => {
                    this.alertService._isLoading = true;
                    this.alertService._type = 'icon-danger';
                    this.alertService._icon = 'fas fa-skull-crossbones';
                    this.alertService._text = 'Demande d\'enregistrement en erreur';
                    observer.next(error);
                });
    });
  }

  /**
   * @param url
   * @param isConnected
   * @param httpParams
   */
  public getObservable(url: string, isConnected?: boolean, httpParams?: any): Observable<any> {
    return new Observable<any>(observer => {
        this.getObservableSubscription = this.httpClient.get(
            BASE_URL + url,
            {
                headers: this.httpAutorizationOauth(isConnected),
                params: this.httpParams(httpParams),
                responseType: 'json'
            })
            .subscribe(
                (resource: any) => {
                    observer.next(resource);
                },
                (error: any) => {
                    observer.next(error);
                });
    });
  }


  private httpAutorizationOauth(isConnected: boolean = false, forFile?: boolean) {
    let headers = new HttpHeaders()
      .append('Access-Control-Allow-Origin', environment.urlFront)
      .append("Content-Type", "application/json");
    if (isConnected === true) {
      headers = headers.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    }
    return headers;
  }

  private httpParams(httpParams: any = {}) {
    if (httpParams === []) {
        return new HttpParams();
    } else {
        return httpParams;
    }
  }

  /**
   * remove /api/ from apiPlatform context url
   * @param url
   */
  public removePrefixApiInUrl(url = '') {
    return url.substring(1);
  }

  createUrl(url: string, criteria: any[]) {
    let i = 0;
    criteria.map(obj => {
        let separateChar = '&';
        if (i === 0) {
            separateChar = '?';
        }
        switch (obj.key) {
            case 'itemsPerPage':
                url += separateChar + obj.key + '=' + obj.value;
                break;
            case 'order':
                url += separateChar + obj.key + '[' + obj?.filter + ']=' + obj.value;
                break;
            default:
                if (typeof obj.value === "boolean") {
                    url += separateChar + obj.key + '=' + obj.value;
                } else {
                    url += separateChar + obj.key + '[' + obj?.filter + ']=' + obj.value;
                }
                break;
        }
        i++;
    });
    return url;
  }

    /**
     * @param url
     * @param formData
     * @param isConnected
     * @param httpParams
     */
    public deleteObservable(url: string, isConnected?: boolean, httpParams?: any[]): Observable<any> {
        return new Observable<any>(observer => {
            this.deleteObservableSubscription = this.httpClient.delete(
                BASE_URL + url,
                {
                    headers: this.httpAutorizationOauth(isConnected),
                    params: this.httpParams(httpParams)
                }).subscribe(
                (resource: any) => {
                    observer.next(resource);
                },
                (error: any) => {
                    observer.next(error);
                });
        });
    }
}
