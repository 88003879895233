import {Component, EventEmitter, Input, Output, Renderer2} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { RouteEventsService } from '../../services/RouteEventsService';
import {FormGeneratePageService} from "../../services/form-generate-page.service";

@Component({
  selector: 'form-generator',
  templateUrl: './form-generator.component.html'
})
export class FormGeneratorComponent {
  submitted = false;
  @Input('formClass') formClass = '';
  @Input('form') form: FormGroup;
  @Input('template') template: any[];
  @Input('isView') isView: boolean;
  @Input('isFormCreator') isFormCreator: number = 0;
  @Output() isSubmitEvent = new EventEmitter();
  @Output() isClearEvent = new EventEmitter();

  selectedItems: any[] = [];

  constructor(
    private routeEventsService: RouteEventsService,
    private router: Router,
    private formGeneratePageService: FormGeneratePageService,
    private renderer: Renderer2
  ) {
  }

  get f() { return this.form.controls; }


  onSubmit(param = '') {
    if(param==='clear()') {
      this.isClearEvent.emit();
    } else {
      this.submitted = true;
      this.isSubmitEvent.emit({
        isSubmitted: this.submitted,
        form: this.form,
        route: param
      });
    }
  }

  cancel() {
    this.router.navigate([this.routeEventsService.previousRoutePath.value]);
  }

  selectedElement($event: MouseEvent,elt: any) {
    if(this.isFormCreator === 1) {
      $event.stopImmediatePropagation();
      if (this.formGeneratePageService._item !== null) {
        this.renderer.removeStyle(this.formGeneratePageService._eltRef,  'border');
      }
      let itemSelected = this.findParentItemSelectedRecursively(elt);
      console.log(itemSelected);
      // if (itemSelected.type === 'group') {
        this.renderer.setStyle($event.target,  'border', 'dotted 2px #3498db');
        console.log(itemSelected);
        console.log($event);
        this.formGeneratePageService._item = elt;
        this.formGeneratePageService._eltRef = $event.target;
      // }
    }
  }




  findParentItemSelectedRecursively(item: any){
    let itemReturn: any = null;
    console.log(item);
    if (item.children !== undefined && item.type !=="actions"){
      item.children.map( (obj: any) => {
        if (obj.children === undefined) {
          return itemReturn = parent;
        } else if (obj.children !== undefined && obj.type !=="actions"){
          return itemReturn = this.findParentItemSelectedRecursively(obj.children);
        }
      });
    } else {
      itemReturn = item;
    }
    return itemReturn;
  }
}
