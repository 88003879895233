import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/core/services/api-service';
import { apiUrls } from 'src/environments/environment';
import {UserService} from "../../services/user.service";

@Component({
  selector: 'default-Item-html',
})
export class DefaultItemComponent implements OnInit {
  user: any;
  item: any;
  criteria: any[] = [];
  defaultService: any;
  constructor(
    protected route: ActivatedRoute,
    protected apiService: ApiService,
    protected userService: UserService
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe( (data: any) => {
      if(data.get('id') && !this.apiService.isTokenExpired()){
        this.apiService.getObservable(apiUrls.getUserByToken, true)
        .subscribe((resource: any) => {
          this.userService._user = this.user = resource;
          this.defaultService.getAPIDefaultById(apiUrls.getLog + this.user.id, false, true)
          .subscribe((item: any) => {
            if (this.user.uniqId === item.uniqId) {
              this.item = item;
            }
          });
        });
      }
    });
  }
}
