import { Injectable } from '@angular/core';
import { DefaultService } from './default-service';
import { SubscriptionService } from './subscription';
import { ApiService } from './api-service';
import { SpinnerService } from './spinner.service';
import { AlertService } from './alert.service';

@Injectable({ providedIn: 'root' })
export class VariableService extends DefaultService {
    urlRedirection = '';
    constructor(
        protected apiService: ApiService,
        protected subscriptionService: SubscriptionService,
        public spinnerService: SpinnerService,
        public alertService: AlertService
    ) {
        super(apiService, subscriptionService, spinnerService, alertService);
        this._getListUrl = 'getVariables';
        this._postUrl = 'postVariable';
        this._getUrl = 'getVariable';
        this._deleteUrl = '';
        this._putUrl = 'putVariable';
    }
}