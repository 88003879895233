import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError((err: any) => {
            switch (err.status){
              // case 0:
              case 401:
                // auto logout if 401 response returned from api
                this.router.navigate(['/auth/login']);
                break;
              case 500:
                // auto logout if 401 response returned from api
                // this.router.navigate(['/auth/login']);
                break;
              case 200:
                break;
              default:
                return throwError(err);
                break;
            }
        }))
    }
}
