import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';


@Injectable()
export class SubscriptionService {
    constructor(
    ) {
    }

    destroyListSubscription(tabSubscription:  Subscription[] = []) {
        tabSubscription.map( subscription => {
            subscription.unsubscribe();
        });
    }
    destroySubscription(subscription) {
        subscription.unsubscribe();
    }
}
