import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AlertService {
    private isLoading: boolean;
    set _isLoading(data: any) {
        this.isLoading = data;
    }

    get _isLoading() {
        return this.isLoading;
    }

    private icon: string = 'fas fa-paper-plane';
    set _icon(data: any) {
        this.icon = data;
    }

    get _icon() {
        return this.icon;
    }

    private type: string = 'icon-primary';
    set _type(data: any) {
        this.type = data;
    }

    get _type() {
        return this.type;
    }

    private text: string = 'Demande d\'enregistrement réussie';
    set _text(data: any) {
        this.text = data;
    }

    get _text() {
        return this.text;
    }

    constructor() {}
}