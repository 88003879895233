import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/core/services/api-service';
import { apiUrls } from 'src/environments/environment';
import {UserService} from "src/app/core/services/user.service";

@Component({
  selector: 'default-form-html',
})
export class DefaultFormComponent implements OnInit {
  user:any;
  form: FormGroup;
  template: any[]= [];
  submitted = false;
  isFormCreate: boolean = true;
  item: any;
  defaultService: any;
  urlPutAdd: string = '';
  isView: boolean = false;

  constructor(
    protected route: ActivatedRoute,
    protected apiService: ApiService,
    protected formBuilder: FormBuilder,
    protected userService: UserService
  ) {
    if(!this.apiService.isTokenExpired()){
      this.apiService.getObservable(apiUrls.getUserByToken, true)
        .subscribe((resource: any) => {
          this.userService._user = this.user = resource;
        });
    }
  }

  ngOnInit(): void {
    this.createForm();
  }

  checkRoutParam(){
    this.route.paramMap.subscribe( (data: any) => {
      let id: number = data.get('id');
      if (id === null) {
        id = this.route.snapshot.parent.params.id;
      }

      if(id !== null){
        this.isFormCreate = false;

        if (this.userService._user !== undefined) {
          this.user = this.userService._user;
          this.getAPIDefaultById(id)
        } else {
          this.apiService.getObservable(apiUrls.getUserByToken, true)
            .subscribe((resource: any) => {
              this.userService._user = this.user = resource;
              this.getAPIDefaultById(id);
            });
        }
      }
    });
  }

  private getAPIDefaultById(id){
    this.defaultService.getAPIDefaultById(id, false, true)
      .subscribe((item: any) => {
        if (this.user.uniqId === item.uniqId) {
          this.item = item;
          this.createForm();
        }
      });
  }

  createForm(){
    const objectsForm: any = {};
    this.template.map( field => {
      if(field.type !== 'actions') {
        if (field.type === 'group') {
          field.children.map(cField => {
            this.createFieldInForm(cField, objectsForm);
          });
        } else {
          this.createFieldInForm(field, objectsForm);
        }
      }
    });
    this.form = this.formBuilder.group(objectsForm);
  }

  private createFieldInForm(field: any, objectsForm){
    if(field.name !== 'password') {
      if (this.item) {
        if(field.type === 'select') {
          let itemSelected: any = null;
          itemSelected = field._items.find(obj => obj['@id'] === this.item[field.name]);
          if (field.prop) {
            objectsForm[field.prop] = [ itemSelected, field.validators];
          } else {
            objectsForm[field.name] = [ itemSelected, field.validators];
          }
        } else {
          objectsForm[field.name] = [ this.item[field.name], field.validators];
        }
      } else {
        if (field.prop) {
          objectsForm[field.prop] = [ null, field.validators];
        } else {
          objectsForm[field.name] = [ null, field.validators];
        }
      }
    } else {
      if (field.prop) {
        objectsForm[field.prop] = [ null, field.validators];
      } else {
        objectsForm[field.name] = [ null, field.validators];
      }
    }
    return objectsForm;
  }

  get f() { return this.form.controls; }
  get v() { return this.form.value; }


  clear() {
    this.form = undefined;
  }

  onSubmitEvent(object){
    if (object['isSubmitted'] && object['form']['status'] === 'VALID') {
      let value: any = {};

      Object.keys(object['form']['value']).forEach((resource: any) => {
        if(object['form']['value'][resource] !== null) {
          value[resource] = object['form']['value'][resource];
        }
        if (object['form']['value'][resource] instanceof Object) {
          value[resource] = object['form']['value'][resource]['@id'];
        }
        if (Array.isArray(object['form']['value'][resource])) {
          value[resource] = [];
          object['form']['value'][resource].map( (item: any) => {
            value[resource].push(item['@id']);
          });
        }
      });
      value['uniqId'] = this.user['uniqId'] ? this.user['uniqId'] : null;
      if(this.item !== null && this.item !== undefined && this.item['method'] !== 'POST') {
        if (this.urlPutAdd === '') {
          this.defaultService.putAPIDefaultById(this.item['id'], value, true)
            .subscribe((resource: any) => {

            });
        } else {
          this.defaultService.putAPIDefaultByUri('/' + apiUrls[this.defaultService._putUrl] +  this.item['id'] + this.urlPutAdd, value, true)
            .subscribe((resource: any) => {

            });
        }
      } else {
        this.defaultService.postAPIDefault(value, true)
          .subscribe((resource: any) => {

          });
      }
    }
  }

}
