import { Injectable } from '@angular/core';
import { DefaultService } from './default-service';
import { SubscriptionService } from './subscription';
import { ApiService } from './api-service';
import { SpinnerService } from './spinner.service';
import { AlertService } from './alert.service';

@Injectable({ providedIn: 'root' })
export class RoleService extends DefaultService {
    urlRedirection = '/security/roles/item/';
    constructor(
        protected apiService: ApiService,
        protected subscriptionService: SubscriptionService,
        public spinnerService: SpinnerService,
        public alertService: AlertService
    ) {
        super(apiService, subscriptionService, spinnerService, alertService);
        this._getListUrl = 'getRoles';
        this._postUrl = 'postRole';
        this._getUrl = 'getRole';
        this._deleteUrl = 'deleteRole';
        this._putUrl = 'putRole';
    }
}