import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/core/services/api-service';
import { apiUrls } from 'src/environments/environment';
import {UserService} from "../../services/user.service";

@Component({
  selector: 'default-list-with-form-html',
})
export class DefaultListWithFormComponent implements OnInit {
  title = '';

  items: any[] = [];
  uriItem: string = "";
  totalItems = 0;
  currentPage = 1;
  previousPage = 1;
  itemsPerPage = 30;
  maxSize = 5;
  user: any;
  criteria: any[] = [];
  columnsTablePrimary: any[];

  form: FormGroup;
  template: any[]= [];
  submitted = false;
  isFormCreate: boolean = true;
  item: any;
  defaultServiceOrigin: any;
  urlPutAdd: string = '';
  isView: boolean = false;
  constructor(
    protected apiService: ApiService,
    protected formBuilder: FormBuilder,
    protected userService: UserService
  ) {
    if(!this.apiService.isTokenExpired()){
      if (this.userService._user === undefined) {
        this.apiService.getObservable(apiUrls.getUserByToken, true)
          .subscribe((resource: any) => {
            this.userService._user = this.user = resource;
          });
      } else {
        this.user = this.userService._user;
      }
    }
  }

  ngOnInit(): void {
  }

  getList() {
    this.defaultServiceOrigin.getAPIListDefaultBy(this.criteria, false, true).subscribe( (resources: any[]) => {
      this.items = resources["hydra:member"];
      console.log(this.items);
      this.uriItem = resources["hydra:view"]['@id'].substring(1,resources["hydra:view"]['@id'].length-1);
      this.totalItems = resources["hydra:totalItems"];
      this.currentPage = resources["hydra:view"]['@id'].substring(resources["hydra:view"]['@id'].length-1);
    } );
  }

  clickEvent(item: any){
    if (item !== null) {
      this.item = item.item;
    }
    if (item.isView !== 'delete') {
      this.isView = item.isView;
      this.createForm(item.isView);
    } else {
      this.defaultServiceOrigin.removeAPIDefaultByUri(this.apiService.removePrefixApiInUrl(this.item['@id']), false, true).subscribe( resource => {
        this.getList();
      });
    }
  }

  createForm(isView: boolean = false){
    const objectsForm: any = {};
    this.template.map( field => {
      if(field.type !== 'actions') {
        if (field.type === 'group') {
          field.children.map(cField => {
            this.createFieldInForm(cField, objectsForm);
          });
        } else {
          this.createFieldInForm(field, objectsForm);
        }
      }
    });
    this.form = this.formBuilder.group(objectsForm);
  }


  private createFieldInForm(field: any, objectsForm){
    if(field.name !== 'password') {
      if (this.item) {
        if(field.type === 'select') {
          let itemSelected: any = null;
          itemSelected = field._items.find(obj => obj['@id'] === this.item[field.name]);
          if (field.prop) {
            objectsForm[field.prop] = [ itemSelected, field.validators];
          } else {
            objectsForm[field.name] = [ itemSelected, field.validators];
          }
        } else {
          objectsForm[field.name] = [ this.item[field.name], field.validators];
        }
      } else {
        if (field.prop) {
          objectsForm[field.prop] = [ null, field.validators];
        } else {
          objectsForm[field.name] = [ null, field.validators];
        }
      }
    } else {
      if (field.prop) {
        objectsForm[field.prop] = [ null, field.validators];
      } else {
        objectsForm[field.name] = [ null, field.validators];
      }
    }
    return objectsForm;
  }

  clear() {
    this.form = undefined;
  }

  loadPage($event){
    if ($event != this.previousPage && this.user) {
      this.criteria.push({
        key: 'page',
        value: $event
      });
      this.getList();
    }
    this.previousPage = $event;
  }

  onSubmitEvent(object){
    if (object['isSubmitted'] && object['form']['status'] === 'VALID') {
      let value: any = {};

      Object.keys(object['form']['value']).forEach((resource: any) => {
        if(object['form']['value'][resource] !== null) {
          value[resource] = object['form']['value'][resource];
        }
        if (object['form']['value'][resource] instanceof Object) {
          value[resource] = object['form']['value'][resource]['@id'];
        }
        if (Array.isArray(object['form']['value'][resource])) {
          value[resource] = [];
          object['form']['value'][resource].map( (item: any) => {
            value[resource].push(item['@id']);
          });
        }
      });
      value['uniqId'] = this.user['uniqId'] ? this.user['uniqId'] : null;
      if(this.item !== null && this.item !== undefined && this.item['method'] !== 'POST') {
        if (this.urlPutAdd === '') {
          this.defaultServiceOrigin.putAPIDefaultById(this.item['id'], value, true)
            .subscribe((resource: any) => {
              this.item = resource;
              this.getList();
            });
        } else {
          this.defaultServiceOrigin.putAPIDefaultByUri('/' + apiUrls[this.defaultServiceOrigin._putUrl] +  this.item['id'] + this.urlPutAdd,
            value,
            true)
            .subscribe((resource: any) => {
              this.item = resource;
              this.getList();
            });
        }
      } else {
        if (object['route'] !== '' && object['route'] !== undefined && object['route'] !== 'onSubmit()') {
          this.defaultServiceOrigin.postAPIByRoute(object['route'], value, true)
            .subscribe((resource: any) => {
              this.item = resource;
              this.getList();
            });
        } else {
          this.defaultServiceOrigin.postAPIDefault(value, true)
            .subscribe((resource: any) => {
              this.item = resource;
              this.getList();
            });
        }
      }
    }
  }
}
