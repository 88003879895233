import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api-service';
import { apiUrls } from 'src/environments/environment';
import {DefaultListWithFormComponent} from './default-list-with-form.component';
import {FormBuilder} from '@angular/forms';
import {UserService} from "../../services/user.service";
import {Router} from "@angular/router";

@Component({
  selector: 'default-list-without-html',
})
export class DefaultListComponent extends DefaultListWithFormComponent implements OnInit {

  items: any[] = [];
  uriItem = '';
  totalItems = 0;
  currentPage = 1;
  previousPage = 1;
  itemsPerPage = 30;
  maxSize = 5;
  user: any;
  criteria: any[] = [];
  defaultService: any;


  columnsTablePrimary = [];
  constructor(
    protected apiService: ApiService,
    protected formBuilder: FormBuilder,
    protected userService: UserService,
    protected router: Router
  ) {
    super(apiService, formBuilder, userService);
  }

  ngOnInit(): void {
    if(!this.apiService.isTokenExpired()){
      if (this.userService._user !== undefined) {
        this.user = this.userService._user;
        this.setCriteria();
      } else {
        this.apiService.getObservable(apiUrls.getUserByToken, true)
          .subscribe((resource: any) => {
            this.userService._user = this.user = resource;
            this.setCriteria();
          });
      }
    } else {
      this.router.navigate(['/auth/login']);
    }
  }

  private setCriteria(){
    this.criteria = [
      // {
      //     key: 'user.id',
      //     value: this.user.id,
      //     filter: '' // gt|gte|lt|lte
      // },
      {
        key: 'uniqId',
        value: this.user.uniqId,
        filter: '' // gt|gte|lt|lte
      },
      {
        key: 'order',
        value: 'DESC',
        filter: 'id' // after|before|strictly_after|strictly_before
      },
      {
        key: 'itemsPerPage',
        value: 30
      }
    ];
    this.getList();
    this.previousPage = 1;
  }

  getList() {
    this.defaultService.getAPIListDefaultBy(this.criteria, false, true).subscribe( (resources: any[]) => {
      this.items = resources["hydra:member"];
      this.uriItem = resources["hydra:view"]['@id'].substring(1,resources["hydra:view"]['@id'].length-1);
      this.totalItems = resources["hydra:totalItems"];
      this.currentPage = resources["hydra:view"]['@id'].substring(resources["hydra:view"]['@id'].length-1);
    } );
  }

  loadPage($event){
    if ($event != this.previousPage && this.user) {
      this.criteria.push({
        key: 'page',
        value: $event
      });
      this.getList();
    }
    this.previousPage = $event;
  }
}
