import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/core/services/api-service';
import { apiUrls, environment } from 'src/environments/environment';
import * as _ from 'lodash';
import {FormBuilder} from "@angular/forms";
import {UserService} from "../../../services/user.service";


@Component({
  selector: 'app-default-list-to-another',
})
export class DefaultListToAnotherComponent implements OnInit
{
  existingObjects: any[] = [];
  objects: any[] = [];
  user: any;
  criteriaObjects: any[] = [];
  criteriaExistingObjects: any[] = [];
  titleObject = '';
  object: any = {};
  property = '';

  constructor(
    protected route: ActivatedRoute,
    protected apiService: ApiService,
    protected router: Router,
    protected defaultService: any,
    protected userService: UserService
  ) {
  }

  ngOnInit(): void {
    if(!this.apiService.isTokenExpired()){
      this.apiService.getObservable(apiUrls.getUserByToken, true)
        .subscribe((resource: any) => {
          this.userService._user = this.user = resource;
          this.generateTables();
        });
    }
  }


  generateTables(){
    this.existingObjects = [];
    this.defaultService.getAPIListDefaultBy(this.criteriaObjects, true, true).subscribe( (resources: any[]) => {
      this.objects = resources["hydra:member"];
      this.defaultService.getAPIListDefaultBy(this.criteriaExistingObjects, true, true).subscribe( (_resources: any[]) => {
        _resources["hydra:member"].map( item => {
          if (this.objects.find( o => o['@id'] === item['@id']) === undefined) {
            this.existingObjects.push(item);
          }
        });
      });
    });
  }

  remove(item){
    _.remove(this.object[this.property], function(uri) {
      return uri === item['@id'];
    });
    this.defaultService.putAPIDefaultByUri(this.object['@id'], this.object, true, true).subscribe( (resource: any) => {
      this.generateTables();
    });
  }

  add(item){
    this.object[this.property].push(item['@id']);
    this.defaultService.putAPIDefaultByUri(this.object['@id'], this.object, true, true).subscribe( (resource: any) => {
      this.generateTables();
    });

  }
}
