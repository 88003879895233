import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SpinnerService {
    private isLoading: boolean;
    set _isLoading(data: any) {
        this.isLoading = data;
    }

    get _isLoading() {
        return this.isLoading;
    }

    constructor() {}
}